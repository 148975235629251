@import './vars.module.scss';

.cardGalleryWrapper {

    margin-bottom: 2em;

    &>header {
        position: relative;
        padding-top: 1em;
        padding-bottom: 1em;

        h1 {
            display: inline-flex;
            align-items: center;
            margin: 0;
            padding: 0;
            font-size: 16pt;
            font-weight: 700;
            text-transform: uppercase;

            span {
                margin-left: .5em;
                font-weight: 600;
                font-size: .8em;
            }
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;

        @media screen and (min-width: $tablets) {
            justify-content: flex-start;
        }

        .frame {
            display: flex;
            width: 100%;
            padding-bottom: .5rem;
            
            @media screen and (min-width: $tablets) {
                padding: .5rem;
                width: 49.5%;
            }

            @media screen and (min-width: $desktops) {
                width: 33.33%
            }

        }
    }
}

.externalLink {
    .label {
        margin-right: .25em;
        font-weight: bold;
    }

    img {
        height: 1em;
        margin-top: -0.25em;
        padding-right: 0.25em;

        &.github {
            height: 1.4em;
            margin-top: -0.25em;
            padding-right: 0.4em;
            padding-left: 0.2em;
        }

        &.pypi {
            height: 1.5em;
            margin-top: -0.25em;
            padding-right: 0.33em;
        }
    }

    a {
        .linkContentsWrapper {
            display: inline-block;
            width: 100%;

            img {
                padding: 0;
                margin-right: .25em;
            }
        }

        &:hover {

            .linkContentsWrapper {
                text-decoration: underline;

                img {
                    filter: brightness(.5);
                }
            }
        }
    }
}

.errorContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .errorMessage {
        max-width: 95%;
        margin: 1rem;
        width: 24rem;
        border-color: $danger;
        background-color: rgba($danger, .1);
        text-align: center;
        color: $white;

        header {
            background-color: rgba($danger, .8);
        }

        .body {
            color: $text;
            font-size: .9rem;

            p {
                margin: 0;
                margin-bottom: .25em;
            }

        }

        footer {
            button {
                background-color: $action-blue;
            }
        }
    }
}