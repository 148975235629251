@import 'styles/vars.module.scss';

body,
#root {
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
}

#root {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

#center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//bootstrap overrides
.card {
  --bs-card-border-radius: #{$ripl-border-radius};
  --bs-card-inner-border-radius: #{$ripl-border-radius};
}

.accordion {
  --bs-accordion-active-bg: #{transparentize($light-blue, .9)};
  --bs-accordion-inner-border-radius: #{$ripl-border-radius};
}

.btn {
  --bs-btn-border-radius: #{$ripl-border-radius};
  @include button-style;
}

a {
  --bs-link-color: #{$aqua};
  color: $aqua;

  &:hover {
    --bs-link-hover-color: #{$dark-blue};
    color: $dark-blue;
  }
}