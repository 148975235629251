@import './vars.module.scss';

.header {
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, .20);
  position: sticky;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 2;

  .container {
    @include fullscreen-width(.5rem, .5rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: $small-devices) {
      flex-direction: row;
      align-items: center;
    }

    @media screen and (min-width: $tablets) {
      justify-content: flex-start;
    }

    .brand {
      display: flex;
      align-items: center;
      padding: 0;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 11pt;

      @media screen and (min-width: $small-devices) {
        padding-top: 6px;
      }

      .italic {
        padding: .25em;
        font-style: italic;
      }

      .logo {
        height: 1.25em;
        padding-left: 3px;
        margin-top: -4px;
      }

    }

    nav {
      ol {
        margin: 0;
        margin-left: .5em;
        font-weight: normal;
        font-size: .9rem;

        @media screen and (min-width: $small-devices) {
          padding-top: .5em;
        }
      }
    }
  }

}

.banner {
  background-color: $aqua;

  .container {
    @include fullscreen-width(1em, 1em);
    text-align: left;
    color: $white;
    font-size: 1rem;

    @media screen and (min-width: $tablets) {
      font-size: 1.2rem;
    }

    a {
      color: $white;
      margin-right: .25em;
      font-weight: 600;
    }
  }
}

.footerBackground {
  background-color: $medium-gray;
}

footer,
.footer {

  .divider {
    height: 150px;
    background-color: $aqua;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $light-gray;
  }

  .container {
    @include fullscreen-width(1rem, 1.5rem);
    display: flex;

    @media screen and (max-width: $desktops) {
      flex-direction: column;
    }

  }

  .logo {
    width: 97px;
    display: inline-block;

    @media screen and (max-width: $desktops) {
      padding-left: 8px;
      margin-bottom: 16px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    padding-left: 50px;

    @media screen and (max-width: $desktops) {
      padding-left: 8px;
      padding-right: 16px;
    }
  }

  .places {
    font-size: 13px;
    font-weight: 800;
    letter-spacing: 1.2px;
    margin-bottom: 22px;
  }

  .bold {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  .small {
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 16px;

    a {
      text-decoration: none;
      color: $text;
    }
  }

  .tiny {
    font-size: 10px;
    font-weight: 500;
  }

  .listWrapper {
    width: 80%;
    margin: 0 auto;
  }

  footer ul {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    li {
      min-height: 100%;
      flex-basis: 18rem;
      background-color: $white;
      margin: .25rem 1rem;
      padding: 1rem;
    }

    figure {
      margin: 0;
      text-align: center;
    }

    figcaption,
    figcaption.small {
      margin: 0;
      margin-top: 1rem;
    }
  }
}

.sockitFooter {
  @include fullscreen-width;
  flex-direction: column;
  justify-content: center;

  header {
    margin: 0;
    padding-top: 1em;
    text-align: center;
  }

  .footerLogoContainer {
    padding-left: 0;

    li {
      display: block;

      .footerLogo {
        text-align: center;

        a {
          img {
            width: 260px;
            max-width: 100%;
            padding: .75em;

            &.logoRIPL {
              margin-bottom: 1em;
            }

            &.logoNLx {
              background-color: $nlx-blue;
            }
          }

          &:hover {
            img {
              filter: brightness(120%);
            }
          }
        }
      }
    }

    &__results {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;

      li {
        width: 50%;
        padding: 0 .5em;

        .footerLogo {
          width: fit-content;

          a {
            img {
              width: 100px;
              padding: .5em;
            }
          }
        }

        &:first-child {
          .footerLogo {
            margin: 0;
            margin-left: auto;
          }
        }
      }
    }
  }
}