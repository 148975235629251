@import './vars.module.scss';

.label {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .001em;
    font-weight: 600;
}

.versionNumber {
    position: fixed;
    bottom: 0;
    right: .5rem;
}

.header {
    padding: .25rem;
    padding-top: .75rem;
    background-color: $medium-gray;

    @media screen and (min-width: $tablets) {
        padding: 1.5rem;
    }

    .headerImage {
        height: 2.5rem;
        position: relative;
        width: 100%;
        display: inline-flex;
        justify-content: center;

        @media screen and (min-width: $tablets) {
            height: 4rem;
        }
    }
}

.content {
    @include fullscreen-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-size: .9rem;

    @media screen and (min-width: $tablets) {
        display: grid;
        grid-template-columns: auto [main-0] 1fr [main-25] 1fr [main-50] 1fr [main-75] 1fr [main-100] auto;
        grid-template-rows: 1fr .5fr 1.25fr .25fr auto;
        grid-template-areas:
            ". desc desc desc desc ."
            ". link1 link1 link2 link2 ."
            ". form form form form ."
            ". resultsHeader . . . ."
            ". results results results results .";
    }

    @media screen and (min-width: $large-screens) {
        grid-template-columns: auto 1fr [main-0] 1fr [main-25] 1fr [main-50] 1fr [main-75] 1fr [main-100] 1fr auto;
        grid-template-rows: 1fr .5fr 1.25fr .25fr auto;
        grid-template-areas:
            ". . desc desc desc desc . ."
            ". . link1 link1 link2 link2 . ."
            ". . form form form form . ."
            ". . resultsHeader . . . . ."
            ". . results results results results . .";
    }

    .description {
        margin: 0;
        padding: .5em .75em;
        padding-bottom: .25em;
        text-align: center;
        line-height: 1.25;

        @media screen and (min-width: $tablets) {
            grid-area: desc;
            max-width: $small-devices;
            font-size: 1rem;
        }

        @media screen and (min-width: $large-screens) {
            max-width: 75%;
            margin-bottom: .5rem;
        }
    }

    &>.link {
        padding: .25em;

        @media screen and (min-width: $tablets) {
            padding: 0 1em;
        }

        a {
            font-weight: 600;
            text-decoration: none;
            color: $text;

            &:hover {
                color: $aqua;
                text-decoration: underline;
            }
        }

        &.link1 {
            grid-area: link1;
            justify-self: flex-end;
        }

        &.link2 {
            grid-area: link2;
            justify-self: flex-start;
        }
    }

    form {
        margin-bottom: 2em;
        padding: 1.5em;
        box-shadow: 0 .5em 2em 0 rgba($black, .05);
        margin-top: .5em;
        background-color: rgba($white, .5);
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        @media screen and (min-width: $tablets) {
            grid-area: form;
            justify-self: stretch;
            margin: .5em;
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-rows: auto;
            grid-template-areas: "formLabel formInput formInput formSubmit";
            align-items: center;
        }

        label {
            grid-area: formLabel;
            justify-self: flex-end;
            margin-right: 1em;
        }

        .mainInput {
            margin: .5em 1em;
            padding: .5em;

            @media screen and (min-width: $tablets) {
                grid-area: formInput;
                justify-self: stretch;
                margin: 0;
                padding: .25em;

            }
        }

        .submitButton {
            grid-area: formSubmit;
            justify-self: flex-start;
            margin: 0 1em;
            padding: .75em;
            border: none;
            border-radius: 1px;
            background-color: $dark-blue;
            color: $white;
            transition: background-color ease-in 100ms;
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear 0s, opacity 300ms;

            &:disabled {
                visibility: hidden;
                opacity: 0;
            }

            &:hover {
                background-color: $blue;
            }

            @media screen and (min-width: $tablets) {
                padding: 0.5em 1.5em;

            }

            &:disabled {
                background-color: transparent;
                color: transparent;
            }
        }
    }

    .outputLabel {
        align-self: flex-start;
        justify-self: flex-start;
        margin: 0 1.5em;

        @media screen and (min-width: $tablets) {
            grid-area: resultsHeader;
            align-self: flex-end;
            margin-top: 1em;
            max-width: 50em;
        }
    }

    output {
        grid-area: results;
        justify-self: stretch;
        padding-bottom: 1em;
        max-width: 100%;

        .noResults {
            padding: 1em;
            font-weight: bold;
            color: $danger;
            text-align: center;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                width: 100%;
            }
        }
    }

    .resultsLoading {
        grid-area: results;
        padding: 2em;
        justify-self: center;
        margin-bottom: 2em;
    }
}

.resultCard {
    display: flex;
    flex-direction: column;
    margin-bottom: .25rem;
    width: 100%;
    position: relative;

    .detachedHead {
        &::after {
            background-image: none;
        }
    }

    .resultCardHead {
        padding: .75rem;

        @media screen and (min-width: $large-screens) {
            padding-right: 2em; //extra space for match badge
        }

        h3 {
            margin: 0;
            width: 100%;
            margin-bottom: 1em;

            @media screen and (min-width: $tablets) {
                margin-bottom: .9em;
            }
        }

        .socCode {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .badge {
                margin-left: .75em;
                font-size: .7rem;
                font-size: .8rem;
                font-weight: 500;
                
                span {
                    font-weight: bolder;
                }
            }
            
            @media screen and (min-width: $tablets) {
                margin-top: 0;
                
                .badge {
                    font-size: .8rem;
                }
            }

            @media screen and (min-width: $large-screens) {

                .badge {
                    font-size: .9rem;
                    position: absolute;
                    top: .5rem;
                    right: 1rem;
                }
            }
        }
    }

    .resultCardBody {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            padding: .5em;
        }

        .videoContainer {
            margin: 1em;
        }

        .cardDetail {
            padding: .25em;
            width: 90%;

            .cardLabel {
                font-weight: bold;
                margin-right: .5em;
            }

            ul {
                list-style: disc;
                padding-left: 2.5rem;
            }

            .outlook {
                font-weight: bold;

                &.bright {
                    color: $success;
                }

                &.average {
                    color: $aqua;
                }

                &.below {
                    color: $danger;
                }
            }

        }

        .careerLink {
            margin: 1em 0;
        }
    }

}

.videoContainer video {
    width: 240px;

    @media screen and (min-width: 530px) {
        width: 400px;
    }

    @media screen and (min-width: 768px) {
        width: 640px;
    }
}