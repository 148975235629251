@import './vars.module.scss';

.researchWrapper {
  header.imageContainer {
    height: 6em;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: $small-devices) {
      height: 8em;
    }

    .headerImg {
      min-height: 100%;
    }

    .productBadge {
      font-size: .75rem;

      .productBadge__icon {
        height: 1.5em;
      }

      &.productBadge--featured {
        position: absolute;
        top: .75em;
        left: .75em;

        @media screen and (min-width: $small-devices) {
          top: 1em;
          left: 1em;
        }

        img {
          filter: grayscale(1) invert(1) brightness(1.75);
        }
      }

      &.productBadge--type {
        background: rgba($white, .9);
        border: solid 1px $white;
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: 5px;
        color: $dark-gray;
        text-transform: uppercase;
        font-weight: bold;
        position: absolute;
        top: .5em;
        right: .75em;

        @media screen and (min-width: $small-devices) {
          top: .75em;
          right: 1em;
        }

        img {
          padding-left: 0.5em;
        }
      }
    }
  }

  .bsCardBody {

    //overwriting bootstrap's .card-body css
    @media screen and (max-width: $small-devices) {
      padding: .75rem;
    }

    &>* {
      padding-top: 4px;
      font-size: 0.9rem;
    }

    .cardTitle {
      font-size: 1.1rem;
    }

    .subheadingItem {
      position: relative;
    }

    .citationWrapper {
      padding: 0;

      &>span {
        padding: 0 .15em;

        &:last-of-type {
          &::after {
            content: '.';
          }
        }
      }

      .cite--italic {
        font-style: italic;
      }

      .cite--bold {
        font-weight: bold;
      }

      .cite--page {
        &::before {
          content: ':';
          margin-left: -0.25em;
          padding-right: 0.1em;
        }
      }

      .doi {
        display: inline-block;
        padding: 0;
        padding-left: .15em;
      }
    }

    .mobileToggle__heading {
      padding: 0;
      position: relative;

      @media screen and (min-width: $small-devices) {
        margin-bottom: 0;
      }

      .mobileToggleHeading__contents {
        position: relative;
      }

      .mobileToggle__button {
        margin-top: .25rem;
        margin-bottom: -0.5rem;
        padding: 0.5rem;
        justify-content: center;
        text-align: center;
        font-size: .9rem;

        &:focus {
          border-color: $action-blue;
        }

        &::after {
          margin-left: .5em;
        }

        @media screen and (min-width: $small-devices) {
          display: none;
          visibility: hidden;
        }
      }
    }

    .productTags {
      font-size: 1rem;
      padding: .75rem 0;
    }

    .productSummary {
      margin: 0;
      padding: 0;
      line-height: 1.25;

      p {
        text-indent: .01em;
        margin-bottom: .5em;
      }
    }
  }

  &.toolCard {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color ease-in 100ms;

    &>header.imageContainer {
      padding: 2em;
      padding-bottom: 0;

      &>img {
        filter: none;
      }
    }

    p.productSummary {
      font-size: 1rem;
      margin-bottom: 1em;
    }

    a {
      width: 50%;
      margin-bottom: 2em;
      font-size: 1rem;
      text-align: center;
      border-color: $text;
      color: $text;
      background-color: $light-gray;

      &:hover {
        background-color: rgba($action-blue, .9);
        border-color: $action-blue;
        color: white;
      }

    }


  }
}