@import './vars.module.scss';

.filtersWrapper {

  .filtersTitle {
    font-size: 1.25rem;
    padding: .75rem;
  }

  .filters {
    display: flex;
    flex-direction: column;
    background-color: $light-gray;

    .container {
      margin-top: .5em;
      margin-bottom: 1em;
    }

    .title {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.filter {
  background-color: $dark-blue;
  color: $white;
  padding: 2px 8px;
  border-radius: 3px;
  margin-right: 8px;
  margin-bottom: 4px;
  display: inline-block;
  font-size: .8rem;
  cursor: default;
  transition: background-color .2s;
}

.filter.clickable {
  border: none;

  &:hover {
    cursor: pointer;
    opacity: .85;
  }

  &:active {
    opacity: 1;
  }
}

.tags {
  background-color: $aqua;
}

.authors {
  background-color: $success;
}

.filterOff {
  background-color: $medium-gray;
  color: $text;
}