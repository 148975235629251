@import './vars.module.scss';

body {
  background: $light-gray;
}

.content {
  @include fullscreen-width;
  position: relative;

  .filterWrapper {
    display: inline-flex;
    position: absolute;
    z-index: 5;
    top: .5em;
    right: 1rem;
    margin-left: auto;
    border: 2px solid transparent;

    button {
      background-color: rgba($white, .5);
      color: $dark-gray;
      padding: 0.375rem 0.75rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: .8rem;
      letter-spacing: -.01em;

      img {
        height: 0.8em;
        margin-top: -0.25em;
        padding-left: .5em;
        
      }

      &:hover {
        color: white;
        background-color: $secondary;

        img {
          filter: brightness(400%)
        }
      }
    }

    &__active {
      border-color: $dark-gray;

      button {
        border-color: transparent;
        background-color: $light-blue;
      }
    }

  }

}


.product {
  background-color: $white;
}